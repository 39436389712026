import img from "../../images/other_services/spazzacamino_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Pulizie Canna Fumaria" prepageName="Servizio" postpageName="Spazza camino e Pulizie Cana Fumaria" />
      <Service
        title="Pulizie Canna Fumaria"
        text="La pulizia dei camini è un lavoro per i professionisti di Manutenpul."
        img={img}
      >
      <br />
      <br />
      Una pulizia specializzata nella pulizia dei camini e canne fumarie
      Con Manutenpul potrai avere:  
      <lu>
        <li>Risolviamo qualsiasi tipo di problemi nel campo della fumisteria.</li>
        <li>Se il tuo camino necessita di manutenzione, interventi di risanamento o ripristino delle funzioni di tiraggio, rivolgiti a noi senza esitazioni</li>
        <li>Il tuo spazzacamino prezzi convenienti</li>
        <li>Manutenpul per ogni vostra necessità sarà a vostra disposizione.</li>
      </lu>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
